<template>
  <msg :config="config" :type='type' :promptVisible="visible" class="employ_msg" :title="title" :cancelDialog="cancelDialogs" :confirmDialog='tipsConfirm' :isdeling='isdeling'>
      <div class="export_tip">导出选项</div>
      <el-radio-group v-model="exportType" @change="selectType" class="export_select">
        <el-radio label="2">导出全部发票</el-radio>
        <el-radio label="1">导出选中发票</el-radio>
      </el-radio-group>

  </msg>
</template>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
      },
      isdeling : {
        type: Boolean,
        default: false,
      },
      title : {
        type: String,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
      confirmDialog: {
        type: Function,
        default: () => { },
      },
    },
    components: {

    },
    data() {
      return {
        config: {
          top: '25vh',
          width: '20rem',
          center: true,
          btnTxt: ['确定','取消'],
        },
        // type:'success',
        type:'warning',
        // type:'error',
        exportType: '2'
      };
    },
    methods: {
      cancelDialogs(){
        this.cancelDialog();
      },
      tipsConfirm(){
        this.confirmDialog();
      },
      selectType(){
        this.$emit('selectType',this.exportType);
      }
    },
    watch:{
    }
  };
</script>

<style lang="less" scoped>
  .export_tip {
    text-align: center;
    font-size: 18px;
    margin-bottom: 35px;
  }
  .export_select {
    width: 100%;
    /deep/ label {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      font-size: 16px !important;
    }
  }
</style>
