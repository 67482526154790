<!--
 * @Author: zhangsheng zhangsheng@whty.com.cn
 * @Date: 2022-12-13 09:15:49
 * @LastEditors: zhangsheng zhangsheng@whty.com.cn
 * @LastEditTime: 2022-12-26 17:11:37
 * @FilePath: \web\src\views\writeOff\invoiceAccount\dialog\push.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <Dialog :config="config" width='20rem' :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='submitForm' :isdeling='isdeling'>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="5rem" class="demo-ruleForm">
        <el-form-item label="推送邮箱" prop="email">
            <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
    </el-form>
  </Dialog>
</template>

<script>
  import Dialog from "@/components/Dialog";
  export default {
    props: {
      visible: {
        type: Boolean,
      },
      isdeling : {
        type: Boolean,
        default: false,
      },
      title : {
        type: String,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
      confirmDialog: {
        type: Function,
        default: () => { },
      },
    },
    components: {
        Dialog
    },
    data() {
      return {
        config: {
          top: '25vh',
          center: true,
          btnTxt: ['确定推送','取消推送'],
        },
        width: '20rem',

        ruleForm: {
          email: '',
        },
        rules: {
          email: [
            { required: true, message: '请输入推送邮箱', trigger: 'blur' },
            { pattern: '^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$', message: '邮箱不合法，请输入正确的邮箱地址', trigger: 'blur' }
          ],
        }
      };
    },
    methods: {
        // tipsConfirm(){
        //     this.confirmDialog();
        // },
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.confirmDialog(this.ruleForm.email);
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
        selectType(){
            this.$emit('selectType',this.exportType);
        }
    },
    watch:{
    }
  };
</script>

<style lang="less" scoped>
  .export_tip {
    text-align: center;
    font-size: 16px;
    margin-bottom: 30px;
  }
  .export_select {
    width: 100%;
    /deep/ label {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      font-size: 16px !important;
    }
  }
</style>
