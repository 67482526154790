<!--
 * @Author: your name
 * @Date: 2021-11-08 17:08:48
 * @LastEditTime: 2023-01-13 16:40:25
 * @LastEditors: zhangsheng zhangsheng@whty.com.cn
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \web-demo\src\views\components\invoiceInfo\dialog\index.vue
-->
<template>
  <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialogs" :confirmDialog='importConfirm' :isdeling='isdeling'>
    <div class="example-drag">
      <file-upload class="upload" :accept="projectName == 'common'?'.pdf,.ofd,.jpg,.jpeg,.png,.bmp,.gif':'.pdf,.ofd,'" :multiple="true" :drop="true" :drop-directory="true" v-model="fileList" @input="updatetValue" @input-filter="inputFilter" ref="upload">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件、文件夹拖到此处，或<em>点击上传</em>
        <!-- process.env['PROJECT_NAME'] == 'common' 本地化区分-->
          <p v-if="projectName == 'common'">支持扩展名：pdf、ofd、图片</p>
          <p v-else>支持扩展名：pdf、ofd</p>
        </div>
      </file-upload>
      <ul v-if="fileList.length" class="showList">
        <li v-for="(file, index) in fileList" :key="file.id">
          <span>{{file.name}}</span>
          <div class="remove" @click="removeHandle(index)"><i class="el-icon-close"></i></div>
        </li>
      </ul>
      <p class="tips" :class="{show: message != '' }">{{message}}</p>
    </div>
  </Dialog>
</template>

<script>
  import Dialog from "@/components/Dialog";
  import FileUpload from 'vue-upload-component'
  import axios from "axios";
  import XLSX from "xlsx";
  // import { employImport } from '@/api/employInfo'

  export default {
    props: {
      visible: {
        type: Boolean,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
       invoiceType:{
         type: String,
       },
       requestFrom:{
         type: String,
       },
       isOpenEinv:{
         type: String,
       }
    },
    components: {
      Dialog,
      FileUpload,
    },
    data() {
      return {
        projectName: process.env['PROJECT_NAME'],
        config: {
          top: '20vh',
          width: '21.2rem',
          title: '发票导入',
          center: true,
          btnTxt: ['导入', '取消'],
        },
        fileList: [],
        fileData: [],
        isdeling: false,
        tableData: [],
        status: false,
        message: ''
      };
    },
    methods: {
      updatetValue(value) {  //文件上传状态更改
      console.log(value,'上传缓存在本地的文件');

        this.fileList = value;
        let  fileNameSet =new Set();
        for (let i = 0; i < this.fileList.length; i++) {
          fileNameSet.add(this.fileList[i].file.name);
        }
         if(fileNameSet.size!=this.fileList.length){
           einvAlert.error("提示","该文件已添加！！")
           this.removeHandle(this.fileList.length-1);
           return false;
         }

        console.log(value);
      },
      inputFilter(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          // const isLt1M = newFile.size / 1024 / 1024 < 1;
          const isLt10M = newFile.size / 1024 / 1024 < 10;
        // 添加文件
        // 过滤非图片文件
        // 不会添加到 files 去
          if(this.projectName == 'common'){
            if (!/\.(pdf|ofd|jpg|jpeg|png|bmp|gif)$/i.test(newFile.name)) {  //过滤文件
              this.message = '不支持"pdf、ofd、图片"以外的格式！'
              return prevent()
            }
          }
          if(this.projectName != 'common'){
            if (!/\.(pdf|ofd)$/i.test(newFile.name)) {  //过滤文件
              this.message = '不支持"pdf、ofd"以外的格式！'
              return prevent()
            }
          }
          if (!isLt10M) {  //过滤文件
            this.message = '上传文件大小不能超过 10MB!'
            // einvAlert.error("提示",this.message);
            return prevent()
          }
        }

      if (newFile && oldFile) {
        // 更新文件
        // 增加版本号
        if (!newFile.version) {
          newFile.version = 0
        }
        newFile.version++
      }

      if (!newFile && oldFile) {
        // 移除文件
        // 拒绝删除文件
        return prevent()
      }
    },
    removeHandle(index){
      this.fileList.splice(index,1)

    },
      cancelDialogs() {
        this.cancelDialog();
        this.fileList = [];
        this.fileData = '';
        this.status = false;
        this.message = '';
      },
      importConfirm() {

        //如果有刷新次数缓存，先去掉
        var userId  = this.getToken("userId");
        var refreshTime = this.getToken("refreshTime"+userId);
        if(refreshTime){
          this.removeItemToken("refreshTime"+userId);
        }


        this.isdeling = true
        let formData = new FormData(); // 批量上传
        //遍历数据，手动注入formData
        for (let i = 0; i < this.fileList.length; i++) {
          formData.append("files", this.fileList[i].file);
        }

        formData.append("companyId", this.getToken('companyId'));
        formData.append("requestFrom", this.requestFrom);
        formData.append("isOpenEinv", this.isOpenEinv);
        formData.append("type", this.invoiceType);
        formData.append("userId", this.getToken('userId'));
        formData.append("asId", 0);
        if (this.fileList && this.fileList.length > 0) {
          /* 路由传值 */
          this.$store.dispatch("PostPreUpload",formData).then(res => {
            // 以文件传输格式 file: (binary) 进行上传
             let  _this=this;
            if(res.success && res.data.needCheck){
              if("1"==this.invoiceType){
                this.$router.push({
                  path:'/income/invoiceAccount/invoiceInfo',
                  query:{
                    /* 业务类型  0-快捷 1-进项，2-销项 */
                    invoiceType:this.invoiceType,
                    requestFrom:this.requestFrom
                    // visible:true
                  }
                })
              }else if("2"==this.invoiceType){
                this.$router.push({
                  path:'/writeOff/invoiceAccount/invoiceInfo',
                  query:{
                    /* 业务类型  0-快捷 1-进项，2-销项 */
                    invoiceType:this.invoiceType,
                    requestFrom:this.requestFrom
                    // visible:true
                  }
                })
              } else {
                this.$router.push({
                  path:'/install/company/invoiceInfo',
                  query:{
                    /* 业务类型  0-快捷 1-进项，2-销项 */
                    invoiceType:this.invoiceType,
                    requestFrom:this.requestFrom
                    // visible:true
                  }
                })
              }
              this.setToken('uploadListShow',true)
            }else if(res.success &&!res.data.needCheck){//不需要校验，再调导入接口
              this.$store.dispatch('PostUpload',{
                clientId:this.getToken('preUploadClientId')
              }).then(res =>{
                this.isdeling = false
                this.$parent.importVis = false
                this.fileList = []
                // 以文件传输格式 file: (binary) 进行上传
                // employImport(formData).then(res => { // 以文件传输格式 file: (binary) 进行上传
                let  _this=this;
                if (res.success && res.data.isSuccess) {
                  this.$parent.getList()
                  einvAlert.success("导入成功","导入成功"+res.data.successCount+"张");
                }else if(res.success){
                  this.$parent.getList()
                  einvAlert.confirm(function (){
                        _this.uploadError(res.data.logId)
                      },"<span>导入成功"+res.data.successCount+"张</span>,"+"<span style='color: red'>导入失败"+res.data.failCount+"张</span>",
                      '下载错误记录','取消');
                }else{
                  einvAlert.error("错误",res.msg);
                }
              }).catch(err => {
                this.isdeling = false
                einvAlert.error("错误",res.msg);
              })

            }else{
              this.isdeling = false
              einvAlert.error("提示",res.msg);
            }

            }).catch(err => {
              this.isdeling = false
          })
        }else{
          this.message = '请选择上传文件';
          this.isdeling = false
        }
      },
      json2Excel(dataSource) {
        var wopts = {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        };
        var workBook = {
          SheetNames: ['Sheet1'],
          Sheets: {},
          Props: {}
        };
        //1、XLSX.utils.json_to_sheet(data) 接收一个对象数组并返回一个基于对象关键字自动生成的“标题”的工作表，默认的列顺序由使用Object.keys的字段的第一次出现确定
        //2、将数据放入对象workBook的Sheets中等待输出
        workBook.Sheets['Sheet1'] = XLSX.utils.json_to_sheet(dataSource)
        //3、XLSX.write() 开始编写Excel表格
        //4、changeData() 将数据处理成需要输出的格式
        this.saveAs(new Blob([this.changeData(XLSX.write(workBook, wopts))], { type: 'application/octet-stream' }), "错误信息")
      },
      changeData(s) {
        //如果存在ArrayBuffer对象(es6) 最好采用该对象
        if (typeof ArrayBuffer !== 'undefined') {
          //1、创建一个字节长度为s.length的内存区域
          var buf = new ArrayBuffer(s.length);
          //2、创建一个指向buf的Unit8视图，开始于字节0，直到缓冲区的末尾
          var view = new Uint8Array(buf);
          //3、返回指定位置的字符的Unicode编码
          for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        } else {
          var buf = new Array(s.length);
          for (var i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        }
      },
      saveAs(obj, fileName) {//当然可以自定义简单的下载文件实现方式
        var tmpa = document.createElement("a");
        tmpa.download = fileName ? fileName + '.xls' : new Date().getTime() + '.xls';
        tmpa.href = URL.createObjectURL(obj); //绑定a标签
        tmpa.click(); //模拟点击实现下载
        setTimeout(function () { //延时释放
          URL.revokeObjectURL(obj); //用URL.revokeObjectURL()来释放这个object URL
        }, 100);
      },
      uploadError(val){
        this.isdeling = true
        axios({
          method: 'get',
          url: '/api/euser/log/downloadAttachment',
          responseType: 'blob',
          params: {
            logId : val
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let fileReader = new FileReader();
          fileReader.onload = function() {
            try {
              let jsonData = JSON.parse(this.result);  // 说明是普通对象数据，后台转换失败
              if (jsonData.status == '40301') { // 接口返回的错误信息
                einvAlert.error("错误",jsonData.message) // 弹出的提示信息
              }
            } catch (err) {   // 解析成对象失败，说明是正常的文件流
              let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = url;
              var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
              link.setAttribute('download', '发票导入失败数据' + now + '.xls')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
          };
          fileReader.readAsText(data)  // 注意别落掉此代码，可以将 Blob 或者 File 对象转根据特殊的编码格式转化为内容(字符串形式)
          this.isdeling = false
        }).catch(err=>{
          this.isdeling = false
        //  einvAlert.error("提示",err)
        })
      }
    },
    watch:{
      message(val){ //状态提示 显隐
        if(val){
          setTimeout(()=>{
            this.message = ''
          },1500)
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .upload{
    background-color: #fff;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    height: 180px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .el-icon-upload {
    font-size: 67px;
    color: #C0C4CC;
    margin: 30px 0 16px;
    line-height: 50px;
  }
  .el-upload__text{
    em{
      font-style: inherit;
      cursor: pointer;
      color: #409EFF;
    }
    p{
      margin: 10px 0;
    }
  }
  .showList{
    margin: 6px 2px;
    padding: 0;
    max-height: 100px;
    overflow-y: scroll;
    li{
      display: flex;
      list-style: none;
      margin: 4px 0px;
      justify-content: space-between;
      span{
        cursor: pointer;
      }
      .remove{
        cursor: pointer;
        margin: 0 20px;
      }
      .remove:hover{
        color: #409EFF;
      }
    }
  }

.tips{
  color: #FF0000;
  padding: 0;
  margin: 0;
}
.show {
  animation: hide 1s ease;
}
@keyframes hide {
  0% {opacity: 0;}
  100% {opacity: 0.9;}
}

</style>
