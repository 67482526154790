import request from '@/utils/request'

// 校验授权码
export function checkPermission(data) {
  return request({
    url: '/api/euser/company/queryAuthorizationCode',
    method: 'post',
    data
  })
}

// 智能取票
export function getInvoice(data) {
  return request({
    url: '/api/einvoice/einv/smartCollectInvoice',
    method: 'post',
    data
  })
}
