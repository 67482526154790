<!--
 * @Author: Zs
 * @Date: 2021-10-19 15:47:58
 * @LastEditTime: 2023-03-24 14:27:56
 * @LastEditors: fangting fangting@whty.com.cn
 * @Description: 发票预览 组件
 * @FilePath: \web\src\views\invoice\modules\dialog\showPdf.vue
-->
<template>
  <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialogs" :confirmDialog='cancelDialogs' :width="width">
    <img :src="fileUrl" class="priviewImg" ref="img" alt="" v-if="isdeling">
    <div v-else class="placeholderBox" v-loading='!isdeling' element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" ></div>
    <div class="bottom_info">
      <span>存储位置：增值税销项电子发票目录/{{year}}年/{{month}}月</span>
      <div>
        <!-- <span class="showFullInvoice" @click="changeSrc" v-if="item.isList != '1' && item.invoiceType !='5'&& item.invoiceType !='6'">全电发票样式预览</span> -->
        <el-button type="primary" class="preview" :loading="isloading" @click="changeSrc" v-if="item.isList != '1' && item.invoiceType !='5'&& item.invoiceType !='6'">全电发票样式预览</el-button>
        <el-button type="primary" class="download" @click="batchDownLoad" v-if="visibleDown" >下载</el-button>
      </div>
    </div>
    
    <Dialog ref="dialog" :config="fullInvoiceConfig" width='46rem' :visible='fullInvoiceVis' :cancelDialog="cancelFullInvoice" :confirmDialog='cancelFullInvoice' :isdeling='isdeling' append>
      <img :src="fullInvoiceSrc" class="priviewImg" ref="img" alt="">
      <div class="bottom_info">
        <span></span>
        <div>
          <el-button type="primary" class="download" @click="changeSrc" >切换</el-button>
        </div>
      </div>
    </Dialog>
    <download-dialog :visible='downloadVis'  @selectType="selectType" @selectAddType="selectAddTypeHandle" :isdeling='isDowning' :confirmDialog='confirmDownLoad' :cancelDialog='cancelDownLoad' ref="download"/>
  </Dialog>
</template>

<script>
  import Dialog from "@/components/Dialog";
  import {getInvoicePicture,getInvoicePicture3}  from '@/api/eInv'
  import downloadDialog from '@/components/downloadInvoice' //下载弹窗引入
  import axios from 'axios'
  export default {
    props: {
      width: {
        type: String,
        default: "46rem"
      }
    },
    components: {
      Dialog,downloadDialog
    },
    data() {
      return {
        fileUrl: '',
        visibleDown: true,
        srcList:null,
        item:{},
        config: {
          top: '10vh',
          width: '46rem',
          title: null,
          center: true,
          btnTxt: [],
        },
        visible: false,
        year:null,
        month:null,
        itemId:'',
        type:'',
        isdeling:false,
        isDowning:false,
        downloadVis: false,
        downloadType: '1',
        selectAddType:false,
        isOnce:true,//预览页面节流

        isloading:false,
        // 全电发票样式预览
        fullInvoiceConfig:{
          top: '10vh',
          type: 'PDF',
          title: '全电发票样式预览',
          center: true,
          btnTxt: [],
        },
        fullInvoiceVis: false,
        fullInvoiceSrc :'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2Fd%2F53e85d4307c60.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673427330&t=bb0c8c0d7c75228cc3b34d01d1d11917',
      };
    },
    created(){
      this.fileUrl = ''
      // console.log('数据渲染了')
    },
    mounted(){
      // this.getFileUrl()
    },
    methods: {
      cancelDialogs(){
        this.visible = false;
      },
      //图片预览
      getFileUrl (item,flag=true){
        if(!this.isOnce) { return false }
        this.isOnce =false
        this.visible = true;// 点击就显示弹窗外框，再加载弹窗内容
        this.visibleDown = flag
        this.item = item;
         this.isdeling = false;
        const date = new Date(item.invoiceTime);
        this.year = date.getFullYear()
        this.month = date.getMonth()+1
        this.month< 10 ? this.month = `0${this.month}` : this.month
        let day = date.getDate()
        day< 10 ? day = `0${day}` : day
        this.itemId = item.id
        const typeMap = {
          '1': 'pdf',
          '2': 'ofd',
          '3': 'jpg',
          '4': 'png',
          '5': 'jpeg',
          '6': 'bmp',
          '7': 'gif'
        }
        this.type = typeMap[item.saveType]
        // this.type = item.fileName.substring(item.fileName.lastIndexOf(".")+1)
        if(item.invoiceCode == null){
          this.config.title = `${item.invoiceNo}_${this.year}${this.month}${day}.${this.type}`
        }else{
          this.config.title = `${item.invoiceCode}_${item.invoiceNo}_${this.year}${this.month}${day}.${this.type}`
        }
        let data ={
            einvToken: this.getCookies('Authorization'),
            companyId : item.companyId,
            invoiceCode : item.invoiceCode,
            invoiceNo : item.invoiceNo,
            invoiceTimes:item.invoiceTime.slice(0,10),
            invoiceId:  item.id
          }
          /* 获取base64的地址 */
        getInvoicePicture(data)
        .then(res => {
            if (res.success) {
              const dataInfo = res.data[0];
              this.fileUrl = 'data:image/jpeg;base64,' + dataInfo;
              // this.srcList = ['data:image/jpeg;base64,' + dataInfo]
              // this.isdeling = false;
            // this.visible = true;
            this.isdeling = true;//得到返回的图片地址时，显示图片
            } else {
              // this.$message.error(res.msg)
              einvAlert.error("预览失败",res.msg||res.message)
              this.visible = false; // 没得到正确的图片地址，直接关闭弹窗
              // this.isdeling = false;
            }
            this.isOnce = true
          }).catch(err => {
            // this.$message.error(err)
            err ? einvAlert.error("预览失败",err.msg||err.message||err) : einvAlert.error("预览失败",'未知异常')
            this.visible = false; // 没得到正确的图片地址，直接关闭弹窗
            this.isOnce = true
          })
          /* 获取base64的地址 end */      
      },
      getFileUrl2 (item,flag=true){
        if(!this.isOnce) { return false }
        this.visibleDown = flag
        this.isOnce =false
        this.visible = true;// 点击就显示弹窗外框，再加载弹窗内容
        this.isdeling = false;
        const date = new Date(item.invoiceTime);
        this.year = item.invoiceYear
        this.month = item.invoiceMonth
        this.month< 10 ? this.month = `0${this.month}` : this.month
        let day = item.invoiceDate
        day< 10 ? day = `0${day}` : day
        this.itemId = item.id
        this.type =item.fileUrl.substring(item.fileUrl.lastIndexOf(".")+1);

        this.config.title = `${item.invoiceCode}_${item.invoiceNo}_${this.year}${this.month}${day}.${this.type}`
        let data ={
          fileUrl : item.fileUrl
        }
        /* 获取base64的地址 */
        getInvoicePicture3(data)
            .then(res => {
              if (res.success) {
                const dataInfo = res.data[0];
                this.fileUrl = 'data:image/jpeg;base64,' + dataInfo;
                this.isdeling = true;//得到返回的图片地址时，显示图片
              } else {
                einvAlert.error("预览失败",res.msg||res.message)

                 this.visible = false; // 没得到正确的图片地址，直接关闭弹窗
              }
              this.isOnce = true
            }).catch(err => {
          // this.$message.error(err)
          err ? einvAlert.error("预览失败",err.msg||err.message||err) : einvAlert.error("预览失败",'未知异常')
          this.visible = false; // 没得到正确的图片地址，直接关闭弹窗
          this.isOnce = true
        })
        /* 获取base64的地址 end */
      },
      //下载
      batchDownLoad(){

        // 全电票直接按原格式不需要销售者下载， 老版发票则弹框允许选择是否下载格式等
        if(this.item.invoiceType == '5' || this.item.invoiceType == '6'){
          this.downloadType = '1';
          this.addSellSeal = false;
          this.confirmDownLoad()
        }else{
          this.downloadVis = true;
        }
      },
      confirmDownLoad(){
        this.isDowning = true
        var type=''
        var fileName = this.config.title
        if(this.downloadType=='2'){
          type = "application/pdf;chartset=UTF-8"
          fileName = fileName.substring(0,fileName.lastIndexOf(".")+1) + "pdf"
        }else if(this.downloadType=='3'){
          type = "application/ofd;chartset=UTF-8"
          fileName = fileName.substring(0,fileName.lastIndexOf(".")+1) + "ofd"
        }else{
          type = "application/jpeg;chartset=UTF-8"
        }
        axios({
          method: 'get',
          url: '/api/einvoice/einv/downloadAddSeal',
          responseType: 'blob',
          params: {
            idList:this.itemId,
            downType: this.downloadType,
            addSeal: this.selectAddType, //是否添加销售章
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          console.log(res,'222222')
          // let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
          let url = window.URL.createObjectURL(new Blob([data], { type: type}))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          link.setAttribute('download',fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.isDowning = false
          this.downloadVis=false
        }).catch(err =>{
          this.isDowning = false;
          this.downloadVis=false
        })
      },
      selectType(value){
        this.downloadType = value
      },
      selectAddTypeHandle(value){
        this.selectAddType = value
      },
      cancelDownLoad(){
        this.downloadVis = false;
      },

      changeSrc(){
        this.isloading = true;
        if(this.fullInvoiceVis){
          this.fullInvoiceVis = false;
          this.isloading = false;
        }else{
          this.$store.dispatch('GetAllElectronicPicture',{
            invoiceId:this.item.invoiceId,
            invoiceTime:this.item.invoiceTime.slice(0,10),
          }).then(res => {
            if (res.success) {
              const dataInfo = res.data;
              console.log(res.data);
              this.fullInvoiceSrc = 'data:image/jpeg;base64,' + dataInfo;
              this.isdeling = true;//得到返回的图片地址时，显示图片
              this.isloading = false;
              this.fullInvoiceVis = true;
            } else {
              einvAlert.error("预览失败",res.msg||res.message)
              this.fullInvoiceVis = false; // 没得到正确的图片地址，直接关闭弹窗
            }
          }).catch(err => {
            err ? einvAlert.error("预览失败",err.msg||err.message||err) : einvAlert.error("预览失败",'未知异常')
            this.fullInvoiceVis = false; // 没得到正确的图片地址，直接关闭弹窗
          })   
        }
      },
      cancelFullInvoice(){
        this.fullInvoiceVis = false;
      }
    },
    watch:{
    }
  };
</script>

<style lang="less" scoped>
  // .box{
  //   min-height: 560px;
  // }
  // .img{
  //   width: 100%;
  // }
  //   .firstStep {
  //   margin: 24px 0 12px;
  // }
  // .upload {
  //   cursor: pointer;
  //   color: #4f71ff;
  // }
  .priviewImg{
    width: 100%;
    height: 480px;
  }
  .main-section{
    min-height: 500px;
  }
  .bottom_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 38px;
    margin: 4px 0 24px;
    .showFullInvoice{
      margin-right: 12px;
      color: #F2972B;
      cursor: pointer;
    }
  }
  .placeholderBox {
    width: 100%;
    height: 490px;
  }
  .download {
    width: 100px;
    height: 40px;
    padding: 0;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
  }
  .preview {
    width: 180px;
    height: 40px;
    padding: 0;
    margin-right: 20px;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
  }
</style>
